<template>
  <div class="popular_up_master">
    <div class="header">
      <div class="back" @click="$router.go(-1)">
        <svg-icon icon-class="back"></svg-icon>
      </div>
      <h2>热门作品</h2>
    </div>
    <div class="main">
      <PullRefresh
        :loading="loading"
        :isHigehtMax="true"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isNoData="isNoData"
      >
        <div class="pull_refresh">
          <div class="info_box" v-for="item in list" :key="item.uid" @click="goPlay(item)">
            <div class="avatar">
              <ImgDecypt :src="item.cover" />
              <TipIcon :videoInfo="item" />
              <div class="video_info">
                <div class="play_count">
                  <svg-icon icon-class="tip_play"></svg-icon>
                  <span>{{ item.playCount | watchCount }}次</span>
                </div>
                <div class="time" v-if="item.playTime">
                  {{ item.playTime | videotime }}
                </div>
              </div>
            </div>
            <div class="info">{{ item.title }}</div>
          </div>
        </div>
      </PullRefresh>
    </div>
    <van-overlay :show="show">
      <div class="wrapper">
        <div class="block">
          <van-swipe class="my-swipe" :show-indicators="false" :autoplay="3000">
            <van-swipe-item v-for="item in workImgs" :key="item">
              <ImgDecypt :src="item" />
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="close" @click="show = false">
          <svg-icon icon-class="close4" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { queryHotList } from '@/api/community';
import TipIcon from '@/components/TipIcon/index.vue';
import PullRefresh from '@/components/PullRefresh';
import { getBeijinTime } from '@/utils';
import ImgDecypt from '@/components/ImgDecypt';
export default {
  name: 'popularWork',
  components: {
    PullRefresh,
    ImgDecypt,
    TipIcon,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 9,
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      activeName: undefined,
      show: false,
      workImgs: [],
    };
  },
  created() {
    this.activeName = this.$route.query.activeName;
    this.getPopularList();
  },
  methods: {
    // 获取热门作品
    async getPopularList(type) {
      let req = {
        pageSize: String(this.pageSize),
        pageNumber: String(this.pageNumber),
        reqDate: getBeijinTime(),
      };
      let res = await this.$Api(queryHotList, req);
      this.loading = false;
      this.refreshing = false;
      if (res.code === 200) {
        let list = res.data.list || [];
        if (type === 'refresh') {
          this.list = list;
        } else {
          this.list = this.list.concat(list);
        }
        if (this.pageNumber == 1 && this.list.length == 0) {
          this.isNoData = true;
        }
        if (!res.data.hasNext) {
          this.finished = true;
          return;
        }
      }
    },
    goPlay(video) {
      if (video.newsType == 'SP') {
        this.$router.push({
          path: '/communityVideo',
          query: {
            vid: video.id,
          },
        });
      }
    },
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.getPopularList();
    },
    onRefresh() {
      this.loading = true;
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.getPopularList('refresh');
    },
  },
};
</script>
<style lang="scss" scoped>
.popular_up_master {
  height: 100%;
  .header {
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #ffffff;
    border-bottom: 0.7px solid #e0e0e0;

    .back {
      position: absolute;
      left: 20px;
      font-size: 24px;
    }
    svg {
      font-size: 26px;
    }
    h2 {
      font-size: 16px;
    }
  }
  .main {
    padding: 10px 12px 0px;
    overflow-y: auto;
    height: calc(100% - 44px);
    -webkit-overflow-scrolling: touch;
    .pull_refresh {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      .info_box {
        width: 111px;
        .avatar {
          height: 177px;
          width: 111px;
          border-radius: 4px;
          overflow: hidden;
          position: relative;
          .video_info {
            height: 20px;
            width: 100%;
            background: linear-gradient(rgba(156, 125, 164, 0), rgba(6, 3, 24, 0.6));
            position: absolute;
            left: 0;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 10px;
            box-sizing: border-box;
            padding: 0 5px;
            border-radius: 6px;
            color: #dcdee0;

            .play_count {
              display: flex;
              align-items: center;
              svg {
                font-size: 13px;
                margin-right: 2px;
              }
            }
          }
        }
        .info {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 6px 0px 6px 3px;
        }
      }
    }
  }
  /deep/ .van-overlay {
    background-color: rgba(0, 0, 0, 0.95);
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    .block {
      height: 394px;
      width: 314px;
      border-radius: 6px;
      overflow: hidden;
      .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: #39a9ed;
      }
    }
    .close {
      height: 33px;
      width: 33px;
      margin-top: 20px;
      svg {
        font-size: 33px;
      }
    }
  }
}
</style>
