<template>
  <div class="video_tip">
    <div v-if="videoInfo.watch">
      <div class="v_gold" v-if="videoInfo.originCoins > 0 && !videoInfo.watch.isFreeWatch">
        <svg-icon icon-class="gold" />
        <span>{{ isVip ? videoInfo.coins : videoInfo.originCoins }}</span>
      </div>
      <div class="v_vip" v-else-if="videoInfo.originCoins == 0 && !videoInfo.watch.isFreeWatch">
        <svg-icon icon-class="tip_vip" />
      </div>
      <div class="v_vip" v-else-if="videoInfo.watch.isFreeWatch">
<!--        <svg-icon icon-class="tip_free" />-->
      </div>
    </div>
    <div v-else class="adv_box">广告</div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    videoInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters({
      isVip: 'isVip',
    }),
  },
};
</script>
<style lang="scss" scoped>
.video_tip {
  position: absolute;
  top: -2.3px;
  left: -1px;
  font-size: 12px;
  // 兼容ios的圆角问题
  transform: translateZ(0px);
  .adv_box {
    width: 41px;
    height: 21px;
    border-radius: 10px 0 11.5px 0;
    background: #ff905c;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 11px;
  }
  svg {
    width: 43px;
    height: 21px;
  }
  .v_gold {
    padding: 2.2px 8px;
    display: flex;
    align-items: center;
    border-radius: 6px 0 11.5px 0;
    color: white;
    background: linear-gradient(#ff4737, #ff455b);
    font-weight: bold;
    svg {
      width: 20px;
      height: 14px;
      color: #ffffff;
    }
  }
}
</style>
